<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template>
      <!-- BODY -->
      <div class="ml-2 mt-2">
        <label>Detalle de la Transacción</label>
        <h3 class="font-weight-bold number-color">
          ID {{ transaccionSelected.id }}
        </h3>

        <label
          >Creado el {{ transaccionSelected.fecha }}
          {{ transaccionSelected.hora }}
        </label>
      </div>
      <div v-if="transaccionSelected.estado == 'Aprobada'" class="ml-2">
        <b-badge
          class="pill-style font-weight-normal"
          :key="transaccionSelected.estado"
          pill
          variant="light-success"
          >{{ transaccionSelected.estado }}</b-badge
        >
      </div>
      <div v-if="transaccionSelected.estado == 'Pendiente'" class="ml-2">
        <b-badge
          class="pill-style font-weight-normal"
          :key="transaccionSelected.estado"
          pill
          variant="light-warning"
          >{{ transaccionSelected.estado }}</b-badge
        >
      </div>
      <div v-if="transaccionSelected.estado == 'Devolución'" class="ml-2">
        <b-badge
          class="pill-style font-weight-normal"
          :key="transaccionSelected.estado"
          pill
          variant="light-danger"
          >{{ transaccionSelected.estado }}</b-badge
        >
      </div>

      <!-- Primer cuadro -->
      <div class="col-12 ml-2 color-container pl-3 py-2">
        <div class="row mb-1">
          <b-col class="col-md-6 col-12 pl-0">
            <feather-icon
              icon="UserIcon"
              size="16"
              class="text-body align-middle"
            />
            <label class="font-weight-bolder ml-1">Cliente</label>
          </b-col>
          <b-col class="col-md-6 col-12 pl-0">
            <label class="ml-md-4 ml-0">{{ transaccionSelected.email }}</label>
          </b-col>
        </div>

        <div class="row mb-1">
          <b-col class="col-md-6 col-12 pl-0">
            <feather-icon
              icon="Minimize2Icon"
              size="16"
              class="text-body align-middle icon-rotate"
            />
            <label class="font-weight-bolder ml-1">Tipo de Transacción</label>
          </b-col>
          <b-col class="col-md-6 col-12 pl-0">
            <label class="ml-md-4 ml-0">{{ transaccionSelected.tipo }}</label>
          </b-col>
        </div>

        <!-- <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon
                  icon="CreditCardIcon"
                  size="16"
                  class="text-body align-middle"
                />
                <label class="font-weight-bolder ml-1">N° de Aprobación</label>
              </b-col>
            </b-row> -->

        <div class="row">
          <b-col class="col-md-6 col-12 pl-0">
            <feather-icon
              icon="CheckCircleIcon"
              size="16"
              class="text-body align-middle"
            />
            <label class="font-weight-bolder ml-1">Método de Pago</label>
          </b-col>
          <b-col class="col-md-6 col-12 pl-0">
            <label class="ml-md-4 ml-0">{{
              transaccionSelected.pasarela
            }}</label>
          </b-col>
        </div>
      </div>
      <!-- <div>
        <b-row class="mb-1 align-items-center">
              <b-col>
                <label class="ml-4">00000000</label>
              </b-col>
            </b-row>
      </div> -->

      <!-- Segundo cuadro -->
      <div class="ml-2 color-container pl-3 py-2">
        <!-- Icono y Título -->
        <div class="row mb-1">
          <b-col class="col-md-6 col-12 pl-0" >
            <feather-icon
              icon="StopCircleIcon"
              size="16"
              class="text-body align-middle"
            />
            <label class="font-weight-bolder ml-1">Moneda</label>
          </b-col>
          <b-col class="col-md-6 col-12 pl-0">
            <label id="contenido-tab" class="ml-md-4 ml-0">{{
              transaccionSelected.monto.moneda
            }}</label>
          </b-col>
        </div>

        <div class="row">
          <b-col class="col-md-6 col-12 pl-0">
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="text-body align-middle"
            />
            <label class="font-weight-bolder ml-1">Monto</label>
          </b-col>
          <b-col class="col-md-6 col-12 pl-0">

            <b-row>
            <b-col>
              <label class="font-weight-bolder ml-2">Total</label>
            </b-col>
            <b-col>
              <label class="ml-4 font-weight-bold"
                >{{ transaccionSelected.monto.moneda }}
                {{ transaccionSelected.monto.cantidad }}</label
              >
            </b-col>
          </b-row>
          </b-col>
          
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BBadge,
  BIcon,
  BIconstack,
  BootstrapVue,
  BootstrapVueIcons,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import { integer } from "vee-validate/dist/rules";

export default {
  components: {
    BRow,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BBadge,
    BIcon,
    BIconstack,
    BootstrapVueIcons,
    BootstrapVue,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    //
    transaccionSelected: { type: Map, required: true },
    /* idTransaccion: { type: String, required: true },
    fecha: { type: Date, required: true },
    estado: { type: String, required: true },
    cliente: { type: String, required: true },
    tipo: {type:String,required:true},
    metodoPago: { type: String, required: false },
    moneda: { type: integer, required: true },
    total: { type: integer, required: true },
    email:{type: String, required:true} */
    //
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      country: "",
      contact: "",
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      store.dispatch("app-user/addUser", userData.value).then(() => {
        emit("refetch-data");
        emit("update:is-add-new-user-sidebar-active", false);
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  .color-container {
    background: #eeeeee;
    width: 90%;
    margin: 16px 16px;
    border-radius: 8px;
  }

  .icon-rotate {
    transform: rotate(45deg);
  }

  .number-color {
    color: black;
  }

  #contenido-tab {
    margin-left: 116px;
  }

  #contenido-tab-monto {
    margin-left: 124px;
  }
}
</style>
